import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container, Row, Carousel } from 'react-bootstrap';
import axios from 'axios';


/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import PropertyBox from '@components/property-box/property-box';

const PropertiesBoxes = (props) => {

  const [propItems, setPropItems] = useState(false)
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {})
      setPropItems(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    let officeId = props.officeId ?? "";
    let area = props.area ?? "";
    let url = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=lettings&_limit=2&_sort=createdAt:DESC&publish=true"; // TODO: base URL supposed to be from .env
    
    if (area) {
      url = url + "&display_address_contains=" + area;
    }
    if (officeId) {
      url = url + "&office_crm_id=" + officeId;
    }

    getitems(url)
  },[])


    const data = useStaticQuery(graphql`
        query {
        pr1: file(relativePath: { eq: "pr1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        pr2: file(relativePath: { eq: "pr2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    let AOS;
    useEffect(() => {
        const AOS = require("aos");
        AOS.init({
            once: true,
            disable: 'mobile'
        });
    }, [])

    useEffect(() => {
        if (AOS) {
            AOS.refresh();
        }
    });

    const Slider = () => (
        <Carousel indicators={true}>
            {propItems && propItems.length > 0 ? propItems.map((item, key) => {
            var checkptype = item.search_type ==="sales"?'/property-for-sale/' :'/property-to-rent/'
            return(
            <Carousel.Item>
                <PropertyBox link={`${checkptype}${item.slug}-${item.id}`} propimage={item.images && item.images.length > 0 ? item.images[0].url : ''} title={item.display_address} price={'£' + item.price.toLocaleString()} beds={item.title} />
            </Carousel.Item>
            )
            }) : ''}
        </Carousel>
    )
    return (
        propItems && propItems.length > 0 &&
            <>
                <Row className="d-none d-lg-flex">
                    {propItems && propItems.length > 0 ? propItems.map((item, key) => {
                        var checkptype = item.search_type ==="sales"?'/property-for-sale/' :'/property-to-rent/'
                        return <Col lg="6">
                            <PropertyBox link={`${checkptype}${item.slug}-${item.id}`} propimage={item.images && item.images.length > 0 ? item.images[0].url : ''} title={item.display_address} price={'£' + item.price.toLocaleString()} beds={item.title} />
                        </Col>
                    }) : ''}
                </Row>
                <div className="d-block d-lg-none">
                    <Slider />
                </div>
            </>
    
    )
}

export default PropertiesBoxes
